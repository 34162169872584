import React, { useState } from 'react';
import {
    SimpleForm,
    useNotify,
    useRedirect,
    fetchUtils,
    TextInput,
    FileInput,
    AutocompleteInput,
    FileField,
    required,
    Loading,
} from 'react-admin';
import inMemoryJWT from '../auth/inMemoryJWT';
import Paper from '@material-ui/core/Paper';
import CountryReferenceInput from './quickCountry/CountryReferenceInput';
import AuthorReferenceInput from './quickAuthor/AuthorReferenceInput';
import EditorialReferenceInput from './quickEditorial/EditorialReferenceInput';
import TopicReferenceInput from './quickTopic/TopicReferenceInput';

function BookCreate( props ) {
    const notify = useNotify();
    const redirect = useRedirect();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async values => {
        setLoading(true);
        const formData = new FormData();
        let headers = new Headers({});
        const token = inMemoryJWT.getToken();
        if (token) {
            headers.set('Authorization', `${token}`);
        } else {
           inMemoryJWT.setRefreshTokenEndpoint(process.env.REACT_APP_API_ENDPOINT+'/refresh');
           return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
               if (gotFreshToken) {
                   headers.set('Authorization', `${inMemoryJWT.getToken()}`);
               };
               return fetchUtils.fetchJson(process.env.REACT_APP_API_ENDPOINT, headers);
           });
        }

        values.book && formData.append("book", values.book.rawFile);
        values.authorsId && formData.append("authorsId", JSON.stringify(values.authorsId));
        values.topicsId && formData.append("topicsId", JSON.stringify(values.topicsId));
        values.title && formData.append("title", values.title);
        values.editorialId && formData.append("editorialId", values.editorialId);
        values.yearPublication && formData.append("yearPublication", values.yearPublication);
        values.yearEdition && formData.append("yearEdition", values.yearEdition);
        values.format && formData.append("format", values.format);
        values.isbn && formData.append("isbn", values.isbn);
        values.countryId && formData.append("countryId", values.countryId);

        return fetch(process.env.REACT_APP_API_ENDPOINT + '/library/book/', {
            method: 'post',
            body: formData,
            headers: headers,
        })
        .then(response => {
            response.json();
            notify("Libro creado correctamente");
            redirect('/book');
            setLoading(false);
        })
        .catch(error => {
            notify(error.message);
            setLoading(false);
        });
    }

    if (loading) return <Loading/>;

    return (
      <Paper elevation={1}>
          <SimpleForm save={handleSubmit}>
                <TextInput label="Título" source="title" />
                <CountryReferenceInput
                    source="countryId"
                    reference="country"
                    allowEmpty
                />
                <AuthorReferenceInput
                    source="authorId"
                    reference="author"
                    allowEmpty
                />
                <EditorialReferenceInput
                    source="editorialId"
                    reference="editorial"
                    allowEmpty
                />
                <TopicReferenceInput
                    source="topicsId"
                    reference="topic"
                    allowEmpty
                />
                <TextInput label="ISBN" source="isbn" />
                <AutocompleteInput label="Formato" source="format" choices={[
                    { id: 'Libro', name: 'Libro' },
                    { id: 'Hemeroteca', name: 'Hemeroteca' },
                    { id: 'Filmina', name: 'Filmina' },
                ]} />
                <TextInput label="Año de publicación" source="yearPublication" />
                <TextInput label="Año de edición" source="yearEdition" />
                <FileInput source="book" label="Archivo pdf del libro" accept="application/pdf" validate={required()}>
                    <FileField source="src" title="title" />
                </FileInput>
          </SimpleForm>
      </Paper>

    );
}

export default BookCreate;
