import React from 'react';
import {
    Button,
    useNotify,
    fetchUtils,
    useRedirect,
} from 'react-admin';
import LockIcon from '@material-ui/icons/Lock';
import inMemoryJWT from '../auth/inMemoryJWT';

const CloseSessionButton = ({ selectedIds }) => {
    const redirect = useRedirect();
    const notify = useNotify();
    
    const handleSubmit = async values => {
        let headers = new Headers({});
        const token = inMemoryJWT.getToken();
        if (token) {
            headers.set('Authorization', `${token}`);
        } else {
            inMemoryJWT.setRefreshTokenEndpoint(process.env.REACT_APP_API_ENDPOINT+'/refresh');
            return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
                if (gotFreshToken) {
                    headers.set('Authorization', `${inMemoryJWT.getToken()}`);
                };
                return fetchUtils.fetchJson(process.env.REACT_APP_API_ENDPOINT, headers);
            });
        }
        return fetch(process.env.REACT_APP_API_ENDPOINT + '/session/' + selectedIds[0] + '/close', {
            method: 'delete',
            headers: headers,
        })
        .then(response => {
            response.json();
            notify('Sesiones cerradas correctamente');
            redirect('/login')}
        )
        .catch(error => {
            notify(error.message);
        });
    };


    return (
        <Button
            label="Cerrar todas las sesiones"
            onClick={handleSubmit}
        >
            <LockIcon />
        </Button>
    );
};

export default CloseSessionButton;
