import React, { useState, useEffect } from 'react';
import {
    SimpleForm,
    useNotify,
    useRedirect,
    fetchUtils,
    TextInput,
    Loading,
    Error,
    FileField,
    FileInput,
    AutocompleteInput,
    required,
} from 'react-admin';
import inMemoryJWT from '../auth/inMemoryJWT';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import CountryReferenceInput from './quickCountry/CountryReferenceInput';
import AuthorReferenceInput from './quickAuthor/AuthorReferenceInput';
import EditorialReferenceInput from './quickEditorial/EditorialReferenceInput';
import TopicReferenceInput from './quickTopic/TopicReferenceInput';
import { Grid, Typography } from '@material-ui/core';
import axios from 'axios';
import CircularProgressWithLabel from './CircularProgressWithLabel';

function BookEdit(props) {
    const notify = useNotify();
    const redirect = useRedirect();
    const [book, setBook] = useState();
    const [loading, setLoading] = useState(true);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState();
    const [uploading, setUploading] = useState(false);
    const [fileExist, setFileExist] = useState(true);

    useEffect(() => {
        let headers = new Headers({ Accept: 'application/json' });
        const token = inMemoryJWT.getToken();
        if (token) {
            headers.set('Authorization', `${token}`);
        } else {
            inMemoryJWT.setRefreshTokenEndpoint(process.env.REACT_APP_API_ENDPOINT + '/refresh');
            return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
                if (gotFreshToken) {
                    headers.set('Authorization', `${inMemoryJWT.getToken()}`);
                };
                return fetchUtils.fetchJson(process.env.REACT_APP_API_ENDPOINT, headers);
            });
        }
        fetch(process.env.REACT_APP_API_ENDPOINT + '/library/book/' + props.id, {
            method: 'get',
            headers: headers,
        })
            .then(response => response.json())
            .then(data => {
                setBook(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [props.id]);

    const handleSubmit = async values => {
        setUploading(true);
        const formData = new FormData();
        let headers = new Headers({});
        const token = inMemoryJWT.getToken();
        if (token) {
            headers.set('Authorization', `${token}`);
        } else {
            inMemoryJWT.setRefreshTokenEndpoint(process.env.REACT_APP_API_ENDPOINT + '/refresh');
            return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
                if (gotFreshToken) {
                    headers.set('Authorization', `${inMemoryJWT.getToken()}`);
                };
                return fetchUtils.fetchJson(process.env.REACT_APP_API_ENDPOINT, headers);
            });
        }
        values.book && formData.append("book", values.book.rawFile);

        values.authorsId && formData.append("authorsId", JSON.stringify(values.authorsId));
        (!(values.topicsId.length == 0)) && formData.append("topicsId", JSON.stringify(values.topicsId));
        values.title && formData.append("title", values.title);
        values.editorialId && formData.append("editorialId", values.editorialId);
        values.yearPublication && formData.append("yearPublication", values.yearPublication);
        values.yearEdition && formData.append("yearEdition", values.yearEdition);
        values.format && formData.append("format", values.format);
        values.isbn && formData.append("isbn", values.isbn);
        values.countryId && formData.append("countryId", values.countryId);

        return axios.put(process.env.REACT_APP_API_ENDPOINT + '/library/book/' + props.id, formData,
            {
                headers: { 'Authorization': token },
                onUploadProgress: progressEvent => {
                    setUploadProgress(parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)));
                }
            }
        )
        .then(response => {
            notify("Libro modificado correctamente");
            redirect('/book')
            setUploading(false);
        })
        .catch(error => {
            notify(error.message);
            setUploading(false);
        });
    }

    const handleDownload = (book) => {
        let headers = new Headers({ Accept: 'application/json' });
        const token = inMemoryJWT.getToken();
        if (token) {
            headers.set('Authorization', `${token}`);
        } else {
            inMemoryJWT.setRefreshTokenEndpoint(process.env.REACT_APP_API_ENDPOINT + '/refresh');
            return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
                if (gotFreshToken) {
                    headers.set('Authorization', `${inMemoryJWT.getToken()}`);
                };
                return fetchUtils.fetchJson(process.env.REACT_APP_API_ENDPOINT, headers);
            });
        }

        const url = process.env.REACT_APP_API_ENDPOINT + '/library/public/book/' + book.id + "/download";
        window.open(url, '_blank');
    }

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!book) return null;

    return (
        <Paper elevation={1}>
            <SimpleForm save={handleSubmit} >
                <TextInput label="Título" source="title" defaultValue={book.title} />
                <CountryReferenceInput
                    source="countryId"
                    reference="country"
                    allowEmpty
                    defaultValue={book.countryId}
                />
                <AuthorReferenceInput
                    source="authorId"
                    reference="author"
                    allowEmpty
                    defaultValue={book.authorsId}
                />
                <EditorialReferenceInput
                    source="editorialId"
                    reference="editorial"
                    allowEmpty
                    defaultValue={book.editorialId}

                />
                <TopicReferenceInput
                    source="topicsId"
                    reference="topic"
                    allowEmpty
                    defaultValue={book.topicsId}

                />
                <TextInput label="ISBN" source="isbn" defaultValue={book.isbn} />
                <AutocompleteInput label="Formato" source="format" defaultValue={book.format} choices={[
                    { id: 'Libro', name: 'Libro' },
                    { id: 'Hemeroteca', name: 'Hemeroteca' },
                    { id: 'Filmina', name: 'Filmina' },
                ]} />
                <TextInput label="Año de publicación" source="yearPublication" defaultValue={book.yearPublication} />
                <TextInput label="Año de edición" source="yearEdition" defaultValue={book.yearEdition} />
                {!fileExist ?
                    <FileInput source="book" label="Archivo pdf del libro" validate={required()}>
                        <FileField title="title" source="url" />
                    </FileInput>
                    :
                    <List dense={true} fullWidth>
                        <Grid container>
                            <DialogTitle>Libro</DialogTitle>
                            <Grid item xs={12}>
                                <Typography>
                                    {book.title}
                                </Typography>
                            </Grid>
                            <IconButton edge="start" aria-label="download" color="primary" onClick={() => handleDownload(book)} >
                                <SaveIcon fontSize="small" />
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" color="secondary" onClick={() => setFileExist(false)} >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                    </List>
                }
                {uploading &&
                    <CircularProgressWithLabel value={uploadProgress} />
                }
            </SimpleForm>
        </Paper>

    );
}

export default BookEdit;
