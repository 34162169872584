import React, { useState, useCallback } from 'react';
import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import TopicQuickCreateButton from './TopicQuickCreateButton';

const useStyles = makeStyles({
    root: {
        display: 'inline-flex',
        flexGrow: 0,
    },
});

const TopicReferenceInput = props => {
    const classes = useStyles();
    const [version, setVersion] = useState(0);
    const handleChange = useCallback(() => setVersion(version + 1), [version]);

    return (
        <div className={classes.root}>
            <ReferenceArrayInput
                {...props}
                key={version}
                source="topicsId"
                reference="topic"
                label="Temas"
                filterToQuery={searchText => ({ name: searchText })}
                suggestionLimit={3}>
                <AutocompleteArrayInput allowEmpty={false} optionText="name" />
            </ReferenceArrayInput>
            <TopicQuickCreateButton onChange={handleChange} />
        </div>
    );
};

export default TopicReferenceInput;
