import React from 'react';
import {
    List,
    TextInput,
    Datagrid,
    TextField,
    Filter,
    Pagination,
    AutocompleteInput,
    ReferenceInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    ReferenceArrayInput,
    SelectArrayInput,
    BulkDeleteButton,
} from 'react-admin';
import { Fragment } from 'react';
import BookDownload from './BookDownload';

const BookBulkActionButtons = (props) => {
    const {permissions} = props;
    return (<Fragment>
        { ((props.selectedIds.length === 1) && 
        permissions === 'lector' )? <BookDownload
            label="Descargar"
            {...props}
            /> : null }
        {(props.selectedIds.length == 1) && 
        (permissions!=="lector") && <BulkDeleteButton {...props} />}
    </Fragment>)
};

const BookPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

export const BookList =  ({ permissions, ...props }) => (
    <List 
        {...props} 
        bulkActionButtons={<BookBulkActionButtons props={props} permissions={permissions}/>}
        pagination={<BookPagination />} 
        exporter={false} 
        filters={<BookFilter />}>
        {permissions !== 'lector' ?
        <Datagrid rowClick="edit">
            <TextField label="Título" source="title" />
            <ReferenceArrayField
                label="Autores"
                reference="author"
                source="authorsId"
            >
                <SingleFieldList>
                    <ChipField source="name"/>
                </SingleFieldList>
            </ReferenceArrayField>
        </Datagrid>:
        <Datagrid>
        <TextField label="Título" source="title" />
            <ReferenceArrayField
                label="Autores"
                reference="author"
                source="authorsId"
            >
                <SingleFieldList linkType="show">
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
        </Datagrid>}
    </List>
);

const BookFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar por título" source="title" alwaysOn autoComplete="off"/>
        <TextInput label="ISBN" source="isbn"/>
        <TextInput label="Año de publicación" source="yearPublication"/>
        <TextInput label="Año de edición" source="yearEdition"/>
        <ReferenceInput label="Editorial" filterToQuery={searchText => ({ name: searchText })} source="editorialId" reference="editorial" allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Países" filterToQuery={searchText => ({ name: searchText })} source="countryId" reference="country" allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput source="topicsId" filterToQuery={searchText => ({ name: searchText })} reference="topic" label="Tema" fullWidth allowEmpty>
            <SelectArrayInput optionText="name" fullWidth style={{ width: 200 }}/>
        </ReferenceArrayInput>
        <ReferenceInput label="Autor" filterToQuery={searchText => ({ name: searchText })} source="authorsId" reference="author" allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

