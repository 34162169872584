import React, {useEffect, useState} from 'react';
import { Button, useNotify, fetchUtils, Loading, Error } from 'react-admin';
import inMemoryJWT from '../auth/inMemoryJWT';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const BookDownload = ({ selectedIds }) => {
    const notify = useNotify();
    const [book, setBook] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        let headers = new Headers({ Accept: 'application/json' });
        const token = inMemoryJWT.getToken();
        if (token) {
            headers.set('Authorization', `${token}`);
        } else {
           inMemoryJWT.setRefreshTokenEndpoint(process.env.REACT_APP_API_ENDPOINT+'/refresh');
           return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
               if (gotFreshToken) {
                   headers.set('Authorization', `${inMemoryJWT.getToken()}`);
               };
               return fetchUtils.fetchJson(process.env.REACT_APP_API_ENDPOINT, headers);
           });
        }
        fetch(process.env.REACT_APP_API_ENDPOINT + '/library/book/' + selectedIds[0], {
            method: 'get',
            headers: headers,
        })
        .then(response => response.json())
        .then(data => {
            setBook(data);
            setLoading(false);
        })
        .catch(error => {
            setError(error);
            setLoading(false);
        })
    }, [ ]);

    if (loading) return <Loading/>;
    if (error) return <Error />;
    if (!book) return null;

    const handleDownload = ( ) => {
        let headers = new Headers({ Accept: 'application/json' });
        const token = inMemoryJWT.getToken();
        if (token) {
            headers.set('Authorization', `${token}`);
        } else {
           inMemoryJWT.setRefreshTokenEndpoint(process.env.REACT_APP_API_ENDPOINT+'/refresh');
           return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
               if (gotFreshToken) {
                   headers.set('Authorization', `${inMemoryJWT.getToken()}`);
               };
               return fetchUtils.fetchJson(process.env.REACT_APP_API_ENDPOINT, headers);
           });
        }
        const url = process.env.REACT_APP_API_ENDPOINT + '/library/public/book/' + selectedIds[0] + "/download";
        window.open(url, '_blank');
    }

    return (
        <Button
            label="Descargar"
            onClick={handleDownload}
        >
            <CloudDownloadIcon />
        </Button>
    );
};

export default BookDownload;