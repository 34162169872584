import React from 'react';
import {
    List,
    TextInput,
    Datagrid,
    TextField,
    Filter,
    Pagination,
    Create,
    Edit,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    PasswordInput,
    BulkDeleteButton
} from 'react-admin';
import { Fragment } from 'react';
import CloseSessionButton from './CloseSessionButton';

const UserPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

const UserBulkActionButtons = props => (
    <Fragment>
        {( props.selectedIds.length === 1 ) ? <CloseSessionButton label="Cerrar sesión" {...props} /> : null}
        {/* default bulk delete action */}
        <BulkDeleteButton {...props} />
    </Fragment>
);

export const UserList = props => (
    <List 
        {...props}
        bulkActionButtons={<UserBulkActionButtons />}  
        pagination={<UserPagination />} 
        filters={<UserFilter />}>
        <Datagrid rowClick="edit">
            <TextField label="Nombre" source="username" />
        </Datagrid>
    </List>
);

export const UserCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="/user">
            <TextInput label="Nombre y apellido" source="username" />
            <ReferenceInput label="Rol" source="rolesId" reference="role">
                <SelectInput source="rolesId"/>
            </ReferenceInput>
            <PasswordInput label="Contraseña" source="password" autoComplete='new-password'/>
        </SimpleForm>
    </Create>
);

export const UserEdit = props => (
    <Edit {...props}>
        <SimpleForm redirect="/user">
            <TextInput label="Nombre y apellido" source="username" />
            <ReferenceInput label="Rol" source="rolesId" reference="role">
                <SelectInput source="rolesId"/>
            </ReferenceInput>
            <PasswordInput label="Contraseña" source="password" autoComplete='new-password'/>
        </SimpleForm>
    </Edit>
);

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" source="username" alwaysOn autoComplete="off"/>
    </Filter>
);
