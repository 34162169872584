import React from 'react';
import { Admin, Resource } from 'react-admin';
import authProvider from './auth/authProvider';
import devDataProvider from './auth/devDataProvider';
import i18nProvider from './i18n/es';
import sessions from './sessions';
import countries from './countries';
import books from './books';
import editorials from './editorials';
import topics from './topics';
import users from './users';
import authors from './authors';
import NotFound from "./generic_component/NotFound";
import { BookList } from "./books/books";
import { AuthorShow, AuthorList } from "./authors/authors";
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import MyLoginPage from './MyLoginPage';
import MyTheme from './MyTheme';

const App = ( ) => (
    <Admin 
    title="Biblioteca popular"
    dataProvider={ devDataProvider } 
    authProvider={ authProvider } 
    i18nProvider={ i18nProvider }
    disableTelemetry
    loginPage={MyLoginPage}
    theme={MyTheme}
    catchAll={NotFound}
    >
        {scope => [
            (scope === 'admin') ?
               [<Resource {...users}/>,
                <Resource {...authors}/>,
                <Resource {...sessions} />,
                <Resource {...topics}/>,
                <Resource {...countries} />,
                <Resource {...editorials}/>,
                <Resource {...books}/>,
                <Resource name="role" />]
            : null,

            (scope === 'cargador') ?
                [<Resource {...authors}/>,
                <Resource {...topics}/>,
                <Resource {...countries} />,
                <Resource {...editorials}/>,
                <Resource {...books}/>,
                <Resource name="role" />]
            : null,

            (scope === 'lector') ?
                [<Resource name="author" list={AuthorList} show={AuthorShow} icon={AccountBoxIcon}/>,
                <Resource name="book" list={BookList} options={{ label:'Libros' }} icon={MenuBookIcon}/>,
                <Resource name="topic"/>,
                <Resource name="country"/>,
                <Resource name="editorial"/>,
                <Resource name="role" />]
            : null,
        ]}

    </Admin>
);

export default App;
