import { BookList } from './books';
import BookCreate from './create';
import BookEdit from './edit';
import MenuBookIcon from '@material-ui/icons/MenuBook';

const index = {
    list: BookList,
    icon: MenuBookIcon,
    create: BookCreate,
    edit: BookEdit,
    options: { label: 'Libros' },
    name: "book",
};

export default index;
