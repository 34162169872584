import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { AuthorCreate, AuthorEdit, AuthorList } from './authors';

const index = {
    list: AuthorList,
    icon: AccountBoxIcon,
    create: AuthorCreate,
    edit: AuthorEdit,
    options: { label: 'Autor' },
    name: "author",
};


export default index;

