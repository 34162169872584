import { SessionList } from './sessions';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const index = {
    list: SessionList,
    icon: VpnKeyIcon,
    options: { label: 'Sesiones' },
    name: "session",
};

export default index;