import simpleRestProvider from 'ra-data-simple-rest';
import httpClient from './httpClient';

const srpAuth = simpleRestProvider(process.env.REACT_APP_API_ENDPOINT, httpClient);
const srpLocal = simpleRestProvider(process.env.REACT_APP_API_ENDPOINT + '/library', httpClient);

const getMethod = type => {
  let name = type.toLowerCase();
  if (!name.includes('_'))
    return name;
  name = name.split('_');
  return name[0]+name[1].charAt(0).toUpperCase()+name[1].slice(1);
};

const authResources = ['user', 'role', 'session'];

const dataProvider = (type, resource, params) => {
  const method = getMethod(type);
  if (authResources.includes(resource))
    return srpAuth[method](resource, params);

  return srpLocal[method](resource, params);
};
export default dataProvider;
