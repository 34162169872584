import React from 'react';
import {
    List,
    TextInput,
    Datagrid,
    TextField,
    Filter,
    Pagination,
    Create,
    Edit,
    SimpleForm,
    Show,
    SimpleShowLayout,
    BulkDeleteButton,
} from 'react-admin';
import { Fragment } from 'react';


const AuthorPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;
const authorRowClick = (permissions) => permissions == 'lector' ?  'show' : 'edit';

export const AuthorList = ({ permissions, ...props }) => (
    <List {...props} 
        pagination={<AuthorPagination />} 
        filters={<AuthorFilter />}
        bulkActionButtons={permissions !== 'lector' ? 
        <AuthorBulkActionButtons props={props} permissions={permissions} /> : false}
    >   
        <Datagrid rowClick={authorRowClick(permissions)}>
            <TextField label="Nombre" source="name" />
        </Datagrid>
    </List>
);

export const AuthorShow = props => (
    <Show title="Author view" {...props}>
        <SimpleShowLayout>
            <TextField label="Nombre y apellido" source="name" />
            <TextField label="Biografía" source="biography" multiline rowsMax={10} rows={20} />
        </SimpleShowLayout>
    </Show>    
);

export const AuthorCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="/author">
            <TextInput label="Nombre y apellido" source="name" />
            <TextInput label="Biografía" source="biography" multiline rowsMax={10} rows={20} />
        </SimpleForm>
    </Create>
);
export const AuthorEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Nombre y apellido" source="name" />
            <TextInput label="Biografía" source="biography" multiline rowsMax={10} rows={20} />
        </SimpleForm>
    </Edit>
);

const AuthorFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" source="name" alwaysOn autoComplete="off"/>
    </Filter>
);

const AuthorBulkActionButtons = (props) => {
    const {permissions} = props;
    return (<Fragment>
        <BulkDeleteButton {...props} /> 
    </Fragment>)
};