import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    Pagination,
    ReferenceInput,
    AutocompleteInput,
    ChipField,
    ReferenceField,
} from 'react-admin';

const SessionPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

export const SessionList = props => (
    <List {...props} pagination={<SessionPagination />} filters={<SessionFilter />}>
        <Datagrid rowClick="edit">
            <TextField label="ip" source="ip" />
            <TextField label="Fecha" source="createdAt" />
            <TextField label="Dispositivo" source="agent" />
            <ReferenceField label="Usuario" reference="user" source="userId" link={() => `/user`}>
                <ChipField source="username" />
            </ReferenceField>
        </Datagrid>
    </List>
);

const SessionFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Usuarios" source="userId" reference="user" allowEmpty>
            <AutocompleteInput optionText="username" />
        </ReferenceInput>
    </Filter>
);
