import React from 'react';
import {
    List,
    TextInput,
    Datagrid,
    TextField,
    Filter,
    Pagination,
    Create,
    Edit,
    SimpleForm,
} from 'react-admin';

const CountryPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

export const CountryList = props => (
    <List {...props} pagination={<CountryPagination />} filters={<CountryFilter />}>
        <Datagrid rowClick="edit">
            <TextField label="Nombre" source="name" />
        </Datagrid>
    </List>
);

export const CountryCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="/author">
            <TextInput label="Nombre" source="name" />
        </SimpleForm>
    </Create>
);

export const CountryEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Nombre" source="name" />
        </SimpleForm>
    </Edit>
);

const CountryFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" source="name" alwaysOn autoComplete="off"/>
    </Filter>
);
