import React, { useState, useCallback } from 'react';
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import EditorialQuickCreateButton from './EditorialQuickCreateButton';

const useStyles = makeStyles({
    root: {
        display: 'inline-flex',
        flexGrow: 0,
    },

});

const EditorialReferenceInput = props => {
    const classes = useStyles();
    const [version, setVersion] = useState(0);
    const handleChange = useCallback(() => setVersion(version + 1), [version]);

    return (
        <div className={classes.root}>
            <ReferenceInput
                {...props}
                key={version}
                source="editorialId"
                reference="editorial"
                label="Editorial"
                filterToQuery={searchText => ({ name: searchText })}
                suggestionLimit={3}>
                <AutocompleteInput allowEmpty={false} optionText="name" />
            </ReferenceInput>
            <EditorialQuickCreateButton onChange={handleChange} />
        </div>
    );
};

export default EditorialReferenceInput;
