import PublicIcon from '@material-ui/icons/Public';
import { CountryCreate, CountryEdit, CountryList } from './countries';

const index = {
    list: CountryList,
    icon: PublicIcon,
    create: CountryCreate,
    edit: CountryEdit,
    options: { label: 'Paises' },
    name: "country",
};

export default index;
