import BookmarksIcon from '@material-ui/icons/Bookmarks';
import { EditorialCreate, EditorialEdit, EditorialList } from './editorials';

const index = {
    list: EditorialList,
    icon: BookmarksIcon,
    create: EditorialCreate,
    edit: EditorialEdit,
    options: { label: 'Editorial' },
    name: "editorial",
};

export default index;
