import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { TopicCreate, TopicEdit, TopicList } from './topics';

const index = {
    list: TopicList,
    icon: AccountTreeIcon,
    create: TopicCreate,
    edit: TopicEdit,
    options: { label: 'Temas' },
    name: "topic",
};

export default index;