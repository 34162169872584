import { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
// import { useCheckAuth } from 'ra-core';
import { LoginForm } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import { Card, Dialog, SvgIcon } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import classnames from 'classnames';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import WhatsappSharpIcon from '@mui/icons-material/WhatsappSharp';
import MailOutlineSharpIcon from '@mui/icons-material/MailOutlineSharp';
import { ReactComponent as InstagramSvg } from './instagram.svg';
import { ReactComponent as YoutubeSvg } from './youtube.svg';

import '@fontsource/roboto/700.css';
import '@fontsource/roboto/400.css';
import { amber } from '@mui/material/colors';

const colores = ["#ffecb3", "#fff8e1"];
const useStylesLogin = makeStyles(
  (theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage: `url("/biblioteca.png")`,
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.2)',
      height: '100%',
      width: '100%'
    },
    card: {
      minWidth: 300,
      marginTop: '0em',
    },
    avatar: {
      margin: '0em',
      display: 'none',
      justifyContent: 'center',
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
    svgStyle: {
      fillColor: 'white'
    }
  }),
  { name: 'RaLogin' }
);

const useStylesLoginForm = makeStyles(
  (theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
      backgroundColor: '#fff8e1',
      '&:hover': {
        backgroundColor: "#ffecb3",
      },
      color: 'black'
    },
  }),
  { name: 'RaLoginForm' }
);

const CustomButton = styled(IconButton)(({ theme }) => ({
  color: "white",
  backgroundColor: "rgba(0, 0, 0, 0)",
  border: '2px solid white',
  '&:hover': {
    color: amber[200],
    backgroundColor: 'white',
  },
}));

const MyLoginPage = () => {
  const classes = useStylesLogin();
  const classesForm = useStylesLoginForm();
  const [showLogin, setShowLogin] = useState(false);
  // const checkAuth = useCheckAuth();
  // const history = useHistory();

  // useEffect(() => {
  //   checkAuth({}, false)
  //     .then(() => {
  //       // already authenticated, redirect to the home page
  //       history.push('/');
  //     })
  //     .catch(() => {
  //       // not authenticated, stay on the login page
  //     });
  // }, [checkAuth, history]);

  return (
    <div className={classnames(classes.main)}>
      <div className={classnames(classes.overlay)}>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} md={4} />
          <Grid item xs={12} md={4} />
          <Grid item xs={12} md={4} >
            <div style={{ marginTop: '100px' }}>
              <Grid item xs={12} textAlign="center">
                <CustomButton href="https://www.instagram.com/biblio29demayo/?hl=es" title='Visita nuestro Instagram' target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
                  <SvgIcon>
                    <InstagramSvg />
                  </SvgIcon>
                </CustomButton>
                <CustomButton style={{ marginRight: '10px' }} title='Visita nuestro canal de YouTube' href='https://www.youtube.com/channel/UCxOwwoM4az_YKZm0BGwJXfA' target="_blank" rel="noopener noreferrer">
                  <SvgIcon>
                    <YoutubeSvg height={25} width={25} />
                  </SvgIcon>
                </CustomButton>
                <CustomButton href="https://wa.me/+5493584114442" title='03584114442' style={{ marginRight: '10px' }}>
                  <WhatsappSharpIcon />
                </CustomButton>
                <CustomButton href="mailto:biblio29demayo@gmail.com" title='biblio29demayo@gmail.com' style={{ marginRight: '10px' }}>
                  <MailOutlineSharpIcon />
                </CustomButton>
                <CustomButton title='Ingresa a la biblioteca digital' onClick={() => setShowLogin(!showLogin)}>
                  <ExitToAppIcon />
                </CustomButton>
              </Grid>
              <Grid item xs={12} >
                <Typography
                  color="white"
                  fontFamily="Roboto"
                  fontWeight={600}
                  fontSize={76}
                  align="center"
                >
                  Biblioteca 29 de mayo
                </Typography>
                <Typography
                  color="white"
                  fontFamily="Roboto"
                  fontWeight={300}
                  fontSize={30}
                  align="center"
                  style={{ marginTop: '30px' }}
                >
                  Sadi Carnot 9, Río Cuarto, Córdoba, Argentina.
                </Typography>
              </Grid>
            </div>
          </Grid>
          <Dialog open={showLogin} onClose={() => setShowLogin(false)}>
            <Card className={classes.card}>
              <LoginForm classes={classesForm} />
            </Card>
          </Dialog>
        </Grid>
      </div>
    </div>
  );
};

export default MyLoginPage;